import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Modal from '../components/Modal';
import MediaSlider from '../components/MediaSlider';
import MediaColumn from '../components/MediaColumn';

const Main = styled.div`
  position: relative;
  max-width: 100rem;
  width: 85%;
  margin: clamp(5rem, 10vw, 10rem) auto;

  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: clamp(2rem, 10vw, 10rem);
`;

const Header = styled.div`
  h1 {
    font-size: clamp(2.5rem, 4vw, 4rem);
    font-weight: 400;
    line-height: 120%;
  }

  p {
    margin-top: clamp(1.5rem, 3vw, 2rem);
    font-size: 1.4rem;
  }
`;

const SingleProjectPage = ({ data: { project } }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    document.querySelector('html').style.overflow = selectedImage
      ? 'hidden'
      : 'visible';
  }, [selectedImage]);

  const onModalClose = () => {
    setSelectedImage(null);
  };

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <>
      <SEO title={project.name} />
      <Main>
        <Header>
          <h1>{project.name}</h1>
          <p>
            {project.collaboration !== null ? 'In collaborazione con ' : ''}
            <span className="bold">{project.collaboration}</span>
          </p>
        </Header>
        <p>{project.description}</p>
      </Main>
      <MediaColumn project={project} />
      <MediaSlider
        project={project}
        onModalClose={onModalClose}
        handleClick={handleClick}
      />
      {selectedImage && (
        <Modal selectedImage={selectedImage} onModalClose={onModalClose} />
      )}
    </>
  );
};

export default SingleProjectPage;

export const query = graphql`
  query($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      name
      location
      description
      collaboration
      id
      images {
        alternativeText
        image {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
